<template>
  <!--  TrueWallet App, TrueWallet Webhook  -->
  <div>
    <div class="row">
      <div class="col-sm-4 col-lg-3">
        <h5>
          True Wallet App
          <AutoBankingStatus :status="!!trueWalletAccount" />
          <br>
          <small class="text-muted">(รองรับฝากเท่านั้น)</small>
        </h5>
      </div>

      <div class="col-sm-8 col-lg-9 pt-2">
        <b-form-checkbox
            v-if="trueWalletAccount"
            id="trueWalletStatus"
            v-model="trueWalletAccount.isEnabled"
            name="trueWalletStatus"
            switch
        >
          เปิดใช้งาน
        </b-form-checkbox>
        <AutoBankingLinkButton
            :status="!!trueWalletAccount"
            @click="$bvModal.show('true-wallet-modal')"
        />
        <TrueWalletModal :agent-bank-account-id="bankAccountForm.id" :true-wallet-account="trueWalletAccount" />
      </div>
    </div>
    <hr />

    <!--    -->
    <div class="row">
      <div class="col-sm-4 col-lg-3">
        <h5>
          True Money Webhook
          <AutoBankingStatus :status="!!trueMoneyWebhook" />
          <br>
          <small class="text-muted">(รองรับฝากเท่านั้น)</small>
        </h5>
      </div>

      <div class="col-sm-8 col-lg-9 pt-2">
        <b-form-checkbox
            v-if="trueMoneyWebhook"
            id="trueWebhookStatus"
            v-model="trueMoneyWebhook.isEnabled"
            name="trueWebhookStatus"
            switch
        >
          เปิดใช้งาน
        </b-form-checkbox>
        <AutoBankingLinkButton
            :status="!!trueMoneyWebhook"
            @click="$bvModal.show('true-money-webhook-modal')"
        />
        <TrueMoneyWebhookModal :agent-bank-account-id="bankAccountForm.id" :true-money-webhook="trueMoneyWebhook" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'AutoTrueWalletForm',
  components: {
    TrueWalletModal: () => import('./true-wallet-modal'),
    TrueMoneyWebhookModal: () => import('./true-money-webhook-modal'),
    AutoBankingStatus: () => import('../auto-banking-status'),
    AutoBankingLinkButton: () => import('../auto-banking-link-button'),
  },
  props: {
    bankAccountForm: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        trueWalletAccount: null,
        trueMoneyWebhook: null,
      })
    }
  },
  data() {
    return {
      trueWalletAccount: null,
      trueMoneyWebhook: null,
    }
  },
  watch: {
    'trueWalletAccount.isEnabled'(newValue, oldValue) {
      if(oldValue !== undefined) {
        this.toggleStatus('true-wallet')
      }
    },
    'trueMoneyWebhook.isEnabled'(newValue, oldValue) {
      if(oldValue !== undefined) {
        this.toggleStatus('true-money-webhook')
      }
    },
  },
  created() {
    this.trueWalletAccount = this.bankAccountForm.trueWalletAccount
    this.trueMoneyWebhook = this.bankAccountForm.trueMoneyWebhook
  },
  methods: {
    ...mapActions(['toggleBankingStatus']),
    toggleStatus(banking) {
      this.toggleBankingStatus({
        id: this.bankAccountForm.id,
        banking,
      })
    }
  }
})
</script>

<style scoped>

</style>